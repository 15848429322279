.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-button {
  display: flex;
  flex-direction: row;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  align-items: center;
  gap: 0.5rem;
  background: #6B7280;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.menu-button:hover {
  background: #4B5563;
}

@keyframes highlight {
  0% {
    background: #6B7280;
    box-shadow: 0 0 0 rgba(79, 70, 229, 0);
  }
  25% {
    background: linear-gradient(45deg, #4F46E5, #7C3AED);
    box-shadow: 0 0 15px rgba(79, 70, 229, 0.5);
  }
  75% {
    background: linear-gradient(45deg, #7C3AED, #4F46E5);
    box-shadow: 0 0 15px rgba(79, 70, 229, 0.5);
  }
  100% {
    background: #6B7280;
    box-shadow: 0 0 0 rgba(79, 70, 229, 0);
  }
}

.highlight {
  animation: highlight 1.5s cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: 2;
}

/* 숫자 변경 애니메이션 */
@keyframes countChange {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.text-lg {
  transition: all 0.3s ease;
}

.highlight .text-lg {
  animation: countChange 1.5s cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: 2;
}